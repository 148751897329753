import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import ResetPassword from 'views/pages/authentication/auth-forms/reset-password';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Consignments = Loadable(lazy(() => import('views/track_trace/Consignments')));
const ConsignmentsCounter = Loadable(lazy(() => import('views/track_trace/Consignmentswithcounters')));
// const CourierConsignments = Loadable(lazy(() => import('views/track_trace/courierconsignments')));
const CourierData = Loadable(lazy(() => import('views/track_trace/Courier')));
const SimData = Loadable(lazy(() => import('views/track_trace/simtrackingdashboard')));
const TrackConsignments = Loadable(lazy(() => import('views/track_trace/trackConsignments')));
const ManageConsignments = Loadable(lazy(() => import('views/track_trace/ManageConsignments')));


 const BillingDashboard = Loadable(lazy(() => import('views/track_trace/changedashboard')));
//  const SampleFile = Loadable(lazy(() => import('views/track_trace/sampledata')));
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const CustomerFeedbacks = Loadable(lazy(() => import('views/track_trace/customerFeedbackComponent')));
const ManualConsignmentTracking = Loadable(lazy(() => import('views/track_trace/manualConsignmentTracking')));
const ConsolidatedReportScreen = Loadable(lazy(() => import('views/track_trace/consolidatedReportScreen')));
const Customers = Loadable(lazy(() => import('views/track_trace/customersComponent')));
const PlantMaster = Loadable(lazy(() => import('views/track_trace/PlantMasterComponent')));
const UserManagement = Loadable(lazy(() => import('views/track_trace/UserManagement')));
const ChangePassword = Loadable(lazy(() => import('views/common/ChangepasswordComp')));
const ReportIssue = Loadable(lazy(() => import('views/common/ReportIssue')));
const Tms = Loadable(lazy(() => import('views/common/tms')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <CourierData />
    },
    {
      path: 'consignmentscounter',element:<Consignments />
    },
    {
      path: 'consignments',element:<ConsignmentsCounter />
    },
    // {
    //   path: 'courierconsignments',element:<CourierConsignments />
    // },
    {
      path: 'couriers',element:<CourierData />
    },
    // {
    //   path: 'deliveredcourierconsignments',element:<CourierData />
    // },
    {
      path: 'dashboard',element:<BillingDashboard />
    },
    {
      path: 'simscreen',element:<SimData />
    },
    {
      path: 'tracking',element:<TrackConsignments />
    },
    {
      path: 'manage',element:<ManageConsignments />
    },
   {
    path:'/customerfeedbacks',element:<CustomerFeedbacks />
   },
   {
    path:'/manualconsignmenttracking',element:<ManualConsignmentTracking />
   },
   {
    path: '/consolidatedreportscreen',element:<ConsolidatedReportScreen/>
   },
   {
    path:'/customers',element:<Customers />
   },
   {
    path:'/plantmaster',element:<PlantMaster />
   },
   {
    path: '/usermanagement', element:<UserManagement/>
   },
   {
    path: '/changepassword', element:<ChangePassword/>
   },
   {
    path: '/reportissue', element:<ReportIssue/>
   },
   {
    path: '/tms', element:<Tms/>
   },
    // {
    //   path: 'sample',element:<SampleFile />
    // },
    
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;
