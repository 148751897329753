import axios from 'axios';
//import axios from 'axios';
var axiosInstance = axios.create({
  /* Setting the base URL for the axios instance. */
   baseURL: 'https://nipromedicalindia.autometrics.in/nipro/',
  // baseURL: 'http://localhost:5007/nipro/',
  
});

export default axiosInstance;
// exports.baseURL = axiosInstance;
