import React from 'react';

// Assuming handleFileChange is defined in your component or passed via props
const FileUploadField = ({ handleFileChange }) => {
    
  return (
    <div style={{ width: '100%' }}>
      <label className='reportIssueLabel'>
        Upload File (Optional)
      </label>
      <input
        type="file"
        name="fileUpload"
        onChange={handleFileChange}
        required={false}
        style={{ display: 'block', width: '100%', height: '38px', marginBottom: "0px" }}
      />
    </div>
  );
};

export default FileUploadField;
