import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import redirectURL from '../redirectURL';

// const issueOptions = [
//     { value: 'Consignment Failure', label: 'Consignment Data Upload Failure' },
//     { value: 'Sim Consent Issue', label: 'SIM Consent Issue' },
//     { value: 'Customer Data Update Problem', label: 'Customer Data Update Problem' }
//   ];

const severityOptions = [
  { value: 'critical', label: 'Critical' },
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' }
];

function ReportIssue() {
  var [show, setShow] = useState(false);
  var [basicTitle, setBasicTitle] = useState('false');
  var [basicType, setBasicType] = useState('');
  const [loadShow, setLoadShow] = useState('show-n');

  const navigate = useNavigate();

  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [description, setDescription] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  var [uploadDoc, setuploadDoc] = useState([]);
  const [issueOptions, setIssueOptions] = useState(null);
  const [severityOptions, setIssueSeverityOptions] = useState(null);

  useEffect(() => {
    const fetchIssueTypeOptions = async () => {
      const params = {}; // Define any params your request needs
      try {
        const response = await redirectURL.post('/getIssueTypeOptions', params);
        console.log(response.data, 'success');
        const options = response.data.records.map((plant) => ({
          value: plant.issue_type_name,
          label: `${plant.issue_type_name}`
        }));
        console.log(options, 'myoptions');
        setIssueOptions(options);
      } catch (error) {
        console.error('Failed to fetch plant options:', error);
      }
    };
    fetchIssueTypeOptions();

    const fetchIssueSeverityTypeOptions = async () => {
      const params = {}; // Define any params your request needs
      try {
        const response = await redirectURL.post('/getIssueSeverityTypeOptions', params);
        console.log(response.data, 'success');
        const options = response.data.records.map((plant) => ({
          value: plant.severity_type_code,
          label: `${plant.severity_type_name}`
        }));
        console.log(options, 'myoptions');
        setIssueSeverityOptions(options);
      } catch (error) {
        console.error('Failed to fetch plant options:', error);
      }
    };
    fetchIssueSeverityTypeOptions();
    
  }, []);

  const [issueDetails, setIssueDetails] = useState({
    issueType: '',
    description: '',
    severity: '',
    email: localStorage.getItem('email'), // Assuming you can auto-capture this
    phoneNumber: '',
    createdDate: new Date(),
    status: "Open",
    is_processed: 0
  });
  console.log(issueDetails, 'mydate');
  const referenceNum = issueDetails.createdDate.toISOString().replace(/\D/g, '');
  console.log(referenceNum, 'referenceNum');

  const closeAlert = () => {
    setShow(false);
    navigate('/tms');
  };

  const onClickClose = () => {
    navigate('/');
  };

  const handleIssueChange = (selectedOption) => {
    // Update both local state and issueDetails state
    setSelectedIssue(selectedOption);
    const updatedIssueType = selectedOption ? selectedOption.value : '';
    setIssueDetails({ ...issueDetails, issueType: updatedIssueType });
  };

  const handleDescriptionChange = (event) => {
    console.log(event.target.value);
    setDescription(event.target.value);
    const updatedIssueType = event ? event.target.value : '';
    setIssueDetails({ ...issueDetails, description: updatedIssueType });
  };

  const handleSeverityChange = (selectedOption) => {
    // Update both local state and issueDetails state
    setSelectedSeverity(selectedOption);
    const updatedSeverity = selectedOption ? selectedOption.value : '';
    setIssueDetails({ ...issueDetails, severity: updatedSeverity });
  };

  var handleFileChange = (event) => {
    console.log("2183", event)

    setuploadDoc(event.target.files[0])
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var isValidStart = /^[6-9][0-9]*$/;
    if (value !== "" && isValidStart.test(value) && value.length <= 10) {
      setMobileNumber(value);
    } else if (value === "") {
      setMobileNumber("");
    }
    setIssueDetails({ ...issueDetails, [name]: value });
  };


  var handleContactNumber = (e) => {
    var inputValue = e.target.value;
    var isValidStart = /^[6-9][0-9]*$/;
    if (inputValue !== "" && isValidStart.test(inputValue) && inputValue.length <= 10) {
      setcontactnumber(inputValue);
    } else if (inputValue === "") {
      setcontactnumber("");
    }
  };


  const handleSubmit = async (e) => {
    setLoadShow("show-m")
    e.preventDefault();
    // Here, you would typically send the issueDetails to your backend server
    console.log(issueDetails, uploadDoc, selectedIssue, "sssssssssssssssssssss");
    var formData2 = new FormData();
    formData2.append("podDoc", uploadDoc);
    formData2.append("issueType", issueDetails.issueType);
    formData2.append("description", issueDetails.description);
    formData2.append("severity", issueDetails.severity);
    formData2.append("email", issueDetails.email);
    formData2.append("phoneNumber", issueDetails.phoneNumber);
    formData2.append("referenceNum", referenceNum);
    formData2.append("createdDate", issueDetails.createdDate);
    formData2.append("status", issueDetails.status);


    // await redirectURL.post('/triggeremail', {}).then((response) => {

    // })
    console.log(formData2, "ssssssssssssssssssss2222222");
    await redirectURL.post('/savereportdata', formData2, {
      headers: {
        'content-type': "multipart/form-data"
      }
    })
      .then((response) => {
        console.log(response, 'response');
        if (response.data.status == 'Success' && response.status == 200) {
          setLoadShow("show-n")
          setShow(true);
          setBasicTitle(`Issue Reported with Id ${referenceNum}`);
          setBasicType('success');

          // window.location.href="/";
        } else {
          setLoadShow("show-n")
          setShow(true);
          setBasicTitle('Something went wrong, please try again');
          setBasicType('danger');
        }
      });
    setSelectedIssue(null);
    setSelectedSeverity(null);
    setDescription('');
    setMobileNumber('');
    setuploadDoc('');
  };

  return (
    <>
      <div className="container-fluid">
        {/* <Breadcrumb parent="Profile" title="Change Password" /> */}
        <SweetAlert show={show} type={basicType} title={basicTitle} onConfirm={closeAlert}></SweetAlert>

        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card" style={{ margin: "11px" }}>
              <div className="card-header">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="bx bx-home"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <span>Report Issue</span>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div>
                  <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                      <div style={{ width: '250px' }}>
                        <label>Issue Category<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                        <Select
                          name="issueType"
                          value={selectedIssue}
                          onChange={handleIssueChange}
                          options={issueOptions}
                          placeholder="Select an Issue"
                          isClearable
                          isSearchable
                          required={true}
                          styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: "10px" }) }}
                        />
                      </div>
                      <div style={{ width: '250px' }}>
                        <label>Severity<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                        <Select
                          name="severitytype"
                          value={selectedSeverity}
                          onChange={handleSeverityChange}
                          options={severityOptions}
                          placeholder="Select Severity"  // Adjusted to match the function of the select
                          isClearable
                          isSearchable
                          required={true}
                          styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                        />
                      </div>
                      <div style={{ width: '250px', marginTop: '8px' }}>
                        <label>Phone Number (Optional)</label>
                        <input type="text" name="phoneNumber" value={mobileNumber} onChange={handleInputChange} style={{ width: '100%', height: '38px' }} />
                      </div>
                      <div style={{ width: '250px', marginTop: '8px' }}>
                        <label>Upload File (Optional)</label>
                        <input
                          type="file"
                          name="fileUpload"
                          onChange={handleFileChange}  // Ensure this function is defined to handle file selections
                          required={false}
                          style={{ display: 'block', width: '100%', height: '38px' }}
                        />
                      </div>
                    </div>


                    <br />
                    <div style={{ width: "100%" }}>
                      <div>
                        <label>Description<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                        <textarea
                          name="description"
                          value={description}
                          onChange={handleDescriptionChange}
                          style={{ marginBottom: "23px" }}
                          required
                          rows="6"
                          cols="30"
                        />
                      </div>
                    </div>
                    <div style={{ float: "right" }}>
                      <button type="submit">Submit</button>
                      {/* <button type="button" style={{marginLeft: "10px"}} onClick={onClickClose}>Close</button> */}
                      <button
                        type="button"
                        style={{
                          marginLeft: '10px',
                          backgroundColor: '#007bff'
                          // color: "white",
                          // border: "none",
                          // padding: "0.375rem 0.75rem",
                          // fontSize: "1rem",
                          // lineHeight: 1.5,
                          // borderRadius: "0.25rem",
                          // cursor: "pointer",
                          // transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out", // Smooth transition
                        }}
                        onClick={onClickClose}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"dataLoad "+loadShow}></div>
            <div className={"dataLoadpageimg " + (loadShow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
      </div>
    </>
  );
}

export default ReportIssue;
