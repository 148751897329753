import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const FeedBackForm = Loadable(lazy(() => import('views/track_trace/submitcustomerfeedback')));
const EnmUserLogin = Loadable(lazy(() => import('views/pages/authentication/EnmUserLogin')));
const ResetPassword = Loadable(lazy(() => import('views/common/reset-password')));
const ShowMap = Loadable(lazy(() => import('views/common/showmap')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/pages/register/register3',
      element: <AuthRegister3 />
    },
    {
      path: 'feedback',element:<FeedBackForm />
    },
    {
      path: 'enmuserlogin/:token', element:<EnmUserLogin />
     },
     {
      path: '/resetpassword', element:<ResetPassword/>
     },
     {
      path: '/map/:value', element:<ShowMap />
     },
  ]
};
export default AuthenticationRoutes;
