import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { AppBar, Toolbar, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate  } from 'react-router-dom';
import redirectURL from '../../../views/redirectURL'
import logo from '../../../assets_new/images/nipro.svg';
// import { MdPeopleAlt } from "react-icons/md";
import userLogo from '../../../assets_new/images/user_png.png'
import usermain from '../../../assets_new/images/userLogo.png'
import key from '../../../assets_new/images/key.png';
import logoutpng from '../../../assets_new/images/logout.png'; 
import ReportIssue from '../../../views/common/ReportIssue'
import MyForm from '../../../views/common/MyForm'
// import MailIcon from '@mui/icons-material/Mail';
// import Logout from '@mui/icons-material/Logout';
// components
// import Profile from './Profile';
// import { IconBellRinging, IconMenu } from '@tabler/icons';

const Header = () => {

  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [sliderTranslate, setSliderTranslate] = useState('');
  const [departmentData, setDepartmentData] = useState([]);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [modules, setModules] = useState([]);
  const [defaultColDef, setDefaultColDef] = useState({});
  const [frameworkComponents, setFrameworkComponents] = useState({});
  const [statusBar, setStatusBar] = useState({});
  const [sideBar, setSideBar] = useState({});
  // const [loadshow, setLoadshow] = useState('');
  const [loadshow, setLoadshow] = useState('show-n');
  const [showDiv, setShowDiv] = useState(''); // Initializing showDiv
  const [uploadDivWidth, setUploadDivWidth] = useState('');
  const [overlay, setOverlay] = useState('show-n');
  const gridApi = useRef(null);
  const gridColumnApi = useRef(null);

  const [showModal, setShowModal] = useState(false);


  const slaData = [
    {
      category: 'Response Time',
      critical: '30 Minutes',
      high: '2 Hours',
      medium: '4 Hours',
      low: '8 Hours'
    },
    {
      category: 'Resolution Time',
      critical: '4-6 Hours',
      high: '1 Business Day',
      medium: '3 Business Days',
      low: '5 Business Days'
    },
    {
      category: '1st Level Escalation',
      critical: '15 Minutes',
      high: '45 Minutes',
      medium: '3 Hours',
      low: '4 Hours'
    },
    {
      category: '2nd Level Escalation',
      critical: '30 Minutes',
      high: '1 Hour',
      medium: '2 Hours',
      low: '5 Hours'
    }
  ];
  
  const escalationEmails = [
    { level: '1st Level', email: 'syamkumar.s@enmovil.in' },
    { level: '2nd Level', email: 'suresh.tembere@enmovil.in' }
  ];

  
  const columnDefs = [
    { headerName: 'Category', field: 'category', sortable: true, filter: true },
    { headerName: 'Critical', field: 'critical', sortable: true, filter: true },
    { headerName: 'High', field: 'high', sortable: true, filter: true },
    { headerName: 'Medium', field: 'medium', sortable: true, filter: true },
    { headerName: 'Low', field: 'low', sortable: true, filter: true }
  ];
  
  const emailColumnDefs = [
    { headerName: 'Escalation Level', field: 'level', sortable: true, filter: true },
    { headerName: 'Email ID', field: 'email', sortable: true, filter: true }
  ];

  
  




  const onCloseUploadDiv = () => {
    setSliderTranslate('');
    setUploadDivWidth('0%'); // Setting width to 0%
    setShowDiv('show-n');
    setOverlay('show-n');
    // If other states need to be reset when closing the upload div, do it here.
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    const sort = [{ colId: 'username', sort: 'asc' }];
    gridApi.current.setSortModel(sort);
  };

  useEffect(() => {
    // Fetch data and update state
    // setDepartmentData(fetchedData);
    // Initialize other state variables or perform any setup tasks
  }, []);
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  // const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));


  // const AppBarStyled = styled(AppBar)(({ theme }) => ({
  //   boxShadow: 'none',
  //   background: theme.palette.background.paper,
  //   justifyContent: 'center',
  //   backdropFilter: 'blur(4px)',
  //   [theme.breakpoints.up('lg')]: {
  //     minHeight: '70px',
  //   },
  // }));
  // const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  //   width: '100%',
  //   color: theme.palette.text.secondary,
  // }));

  // useEffect(() => {
  //   if(localStorage.getItem("token") === "" || localStorage.getItem("token") === null || localStorage.getItem("token") === undefined)
  //   {
  //     navigate("/login")
  //   }
  // },[])

  var columnwithDefsForDCT = [    
    {
        headerName: "User Name",
        field: "username",
        width:190,
        filter: true,
        resizable: true,
        editable: false,
        sortable : true,  
    },
    {
        headerName: "Email",
        field: "email",
        width:250,
        filter: true,
        resizable: true,
        editable: false,        
    },
    {
        headerName: "Phone",
        field: "phone",
        width:190,
        filter: true,
        resizable: true,
        editable: false,
    }
]
  const [isOpen, setIsOpen] = useState(false);
  const toggleRef = useRef(null);
  const dropdownRef = useRef(null);
  var [username, setusername] = useState("");

  // This function toggles the dropdown menu
  const toggleDropdown = () => setIsOpen(!isOpen);

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; 
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  const getusername = () => {
    let firstName = localStorage.getItem("firstname");
    let lastName = localStorage.getItem("lastname");
    firstName = (firstName && firstName !== "undefined") ? capitalizeFirstLetter(firstName) : '';
    lastName = (lastName && lastName !== "undefined") ? capitalizeFirstLetter(lastName) : '';
    let fullName = `${firstName} ${lastName}`.trim();
    setusername(fullName)
  }

  const onClickReport = () => {
    setShowModal(true)
  }

  useEffect(() => {
    getusername()
    if(localStorage.getItem("token") === "" || localStorage.getItem("token") === null || localStorage.getItem("token") === undefined)
    {
      navigate("/login")
    }
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const onShowUploadDiv = () => {
    setLoadshow("show-m")
    const requestParam = {
      userType: "DCTUSER"
    };

    redirectURL.post("/consignments/getUsers", requestParam)
      .then(response => {
        const departments = response.data;
        if (departments.length > 0) {
              console.log("response", response.data);
              setDepartmentData(response.data);
              setLoadshow('show-n');
           
        } else {
          setLoadshow('show-n');
        }
        setUploadDivWidth('30%');
        setSliderTranslate("slider-translate0");
        setOverlay("show-m")
        setShowDiv('show-m');
      })
      .catch(e => {
        console.error("Error", e);
      });
  }

  const onclickhandleclose = () => {
    setShowModal(false)
    window.location.reload();
  }

  const logout=()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('dept_code');
    localStorage.removeItem('is_admin');
    localStorage.removeItem('is_dept_admin');
    localStorage.removeItem('is_global');
    localStorage.removeItem('firstname');
    localStorage.removeItem('username');
    localStorage.removeItem('lastname');
    localStorage.removeItem('email');
    localStorage.removeItem('user_type');							
    localStorage.removeItem('usermenus');
    localStorage.removeItem('usermenucodes');
    localStorage.removeItem('roles');
    localStorage.removeItem('m');
    localStorage.clear();
    navigate("/login")
  }

  const changepassword = () => {
    navigate("/changepassword")
  }

  return (
    <div className="logo_items flex">
		<div className="">

    <div className={"slide-r0 " + sliderTranslate} style={{ width: "50%", marginTop: "50px" }}>

    <div className="slide-r-title0" style={{height: "40px"}}>
        <h4 style={{marginTop: "12px"}}>
        Response Time, SLA & Escalation Matrix:
          {/* <span onClick={onCloseUploadDiv} className={"btn btn-warning c-btn0 pull-right0 " + sliderTranslate}>X</span> */}
          <span style={{marginTop: "6px"}} onClick={onCloseUploadDiv} className={"btn btn-warning c-btn0 pull-right0 " + sliderTranslate}>X</span>
        </h4>
      </div>

      {/* Escalation Table Data */}

      <div style={{padding: "12px"}}>
      <div>
      {/* <h3>Response Time, SLA & Escalation Matrix:</h3> */}

      <h4>1. Response Time:</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead>
          <tr>
            <th colSpan="2" style={{ border: '1px solid black', padding: '8px', backgroundColor: '#3492ff', color: "#fff" }}>Response Time</th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Severity</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Response Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Critical</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>30 Minutes</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>High</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>2 Hours</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Medium</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>4 Hours</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Low</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>8 Hours</td>
          </tr>
        </tbody>
      </table>

      <h4>2. Resolution Time:</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
        <tr>
            <th colSpan="2" style={{ border: '1px solid black', padding: '8px', backgroundColor: '#3492ff', color: "#fff" }}>Resolution Time</th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Severity</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Resolution Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Critical</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>4-6 Hours</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>High</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>1 Business Day</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Medium</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>3 Business Days</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Low</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>5 Business Days</td>
          </tr>
        </tbody>
      </table>

      <h4>3. Escalation Time:</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
        <tr>
            <th colSpan="3" style={{ border: '1px solid black', padding: '8px', backgroundColor: '#3492ff', color: "#fff" }}>Time Based Escalation Matrix</th>
          </tr>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Severity</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>1st Level Escalation</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>2nd Level Escalation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Critical</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>15 Minutes</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>30 Minutes</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>High</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>45 Minutes</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>1 Hour</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Medium</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>3 Hours</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>2 Hours</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Low</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>4 Hours</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>5 Hours</td>
          </tr>
        </tbody>
      </table>

      <h4>4. Escalation Email Ids:</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Escalation Level</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Email ID</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>1st Level</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>syamkumar.s@enmovil.in</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>2nd Level</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>suresh.tembere@enmovil.in</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <div className="slide-r-title0" style={{height: "40px"}}>
        <h4 style={{marginTop: "13px"}}>
        User Details
        </h4>
      </div>

      <div className="slide-r-body0" style={{ position: "relative", height:"200px" }}>
        <div className={"dataLoad " + loadshow}>
          <div style={{ position: "absolute", left: "38%" }}>
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div id="myGrid" style={{ width: "100%", height: "125px", marginBottom: "5px" }} className="ag-theme-balham">
            <AgGridReact
              modules={modules}
              columnDefs={columnwithDefsForDCT}
              defaultColDef={defaultColDef}
              rowData={departmentData}
              enableCharts={false}
              onGridReady={onGridReady}
              frameworkComponents={frameworkComponents}
              statusBar={statusBar}
              sideBar={sideBar}
              stopEditingWhenGridLosesFocus={true}
              // paginationPageSize={paginationPageSize}
              // pagination={true}
              gridOptions={{
                context: { componentParent: this }
              }}
              enableRangeSelection={true}
            />
          </div>
        </div>
      </div>
    </div>        

    <div className={"overlay "+overlay} onClick={onCloseUploadDiv}></div>
			<span className="nav_image">
			</span>
			<i className="bx bx-lock-alt" id="lock-icon" title="Unlock Sidebar"></i>
			<i className="bx bx-x" id="sidebar-close"></i>
		</div>
		<div className="topheader">
			<div className="logo-panel">
				<img src={require("../../../assets/images/logos/enmovil_logo.png")} alt="ENmovil Logo" className="logo-img" />
			</div>
			<div className="right-panel" style={{display: "flex", justifyContent: "flex-end"}}>
      <div className="col" style={{display: "flex", alignItems: "center"}}>
      
      <button style={{marginTop: "1px", float: "left", backgroundColor: "yellow", color: "#333"}} onClick={onClickReport}>Report Issue</button>
      <MyForm showModal={showModal} handleClose={onclickhandleclose} />
      <ul style={{paddingLeft: "5px"}}>
        <li>
          <a href="#" className="txt-dec-none clr333" onClick={onShowUploadDiv}>
          {/* <img src={support} alt="support" style={{width:"32px", marginTop: "11px", marginRight: "9px" ,background:"#ffffff", marginTop: "11px", padding: "3px"}} /> */}
          <i class='bx bxs-user-detail f30' style={{width:"40px", marginTop: "11px", marginRight: "9px" , marginTop: "11px", padding: "3px", paddingLeft: "0px !important"}}></i>
          {/* <MdPeopleAlt size={30}/> */}
          </a>
        </li>
      </ul> 
      <span>
        <img src={logo} alt="Nipro" style={{width:"100px",background:"#ffffff", marginTop: "11px", padding: "3px"}} />
      </span>
      </div>
        <div className="dropdown col">
          <ul style={{width: "0px", height: "0px"}}>
            <li>
            {/* {showForm && <ReportIssue onClose={() => setShowForm(false)} />} */}
              {/* <UserMenu /> */}
              {/* <a href="javascript:;" style={{float:"right",marginTop:"3px"}} onClick={logout}>Logout</a> */}
              <div style={{}}>
              <a className='dropdown-toggle' href="javascript:;" style={{float:"right",marginTop:"3px"}}>
                  <img ref={toggleRef} onClick={toggleDropdown} src={userLogo} style={{color: "#ffffff"}} alt="user" />
              </a>
              </div>
              {isOpen && (
                <div className='dropdown-content' ref={dropdownRef} style={{left:"-118px", position: 'absolute', border: '1px solid #ddd', padding: '10px', backgroundColor: 'white', borderRadius: '6px' }}>
                  <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                    <li style={{ margin: '6px 0', padding: '0', display: 'flex', alignItems: 'center' }}>
                      <img src={usermain} alt='user' style={{ height: '20px', marginRight: '10px' }} />
                      <button style={{color: 'black', fontWeight: 'bold',cursor: 'default', background: 'none', border: 'none', padding: '0'}} onClick={() => console.log('User Profile')}>
                        {username}
                      </button>
                    </li>
                    <li onClick={changepassword} style={{ margin: '6px 0', padding: '0', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <img src={key} alt='key' style={{  height: '20px', marginRight: '10px' }}/>
                      <button style={{ color: 'black', fontWeight: 'bold', background: 'none', border: 'none', padding: '0' }}>Change Password</button>
                    </li>
                    <li onClick={logout} style={{ margin: '6px 0', cursor: 'pointer', padding: '0', display: 'flex', alignItems: 'center' }}>
                      <img src={logoutpng} alt='key' style={{ height: '20px',  marginRight: '10px' }} />
                      <button style={{ color: 'black', fontWeight: 'bold', background: 'none', border: 'none', padding: '0' }}>Logout</button>
                    </li>

                  </ul>
              </div>
              )}

            </li>
          </ul>
        </div>
			</div>
			
		</div>
  </div>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};



export default Header;
