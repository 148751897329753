import React,{useEffect} from "react";

// import $ from 'jquery';

// import { useMediaQuery, Box, Drawer } from '@mui/material';

  // const sidebarWidth = '270px';
  
  
function Sidebar(){

  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  // const sidebarWidth = '270px';
  useEffect(()=>{
    // console.log("asda")
    const sidebar = document.querySelector(".sidebar");
    if(sidebar)
    {
      sidebar.addEventListener("mouseleave", hideSidebar);
      sidebar.addEventListener("mouseenter", showSidebar);
    }

  })
  
  const showSidebar = () => {
    if (document.querySelector(".sidebar").classList.contains("hoverable")) {
      document.querySelector(".sidebar").classList.remove("closenav");
    }
  };
  // const toggleLock = () => {
  //   document.querySelector(".sidebar").classList.toggle("locked");
  //   // If the sidebar is not locked
  //   if (!document.querySelector(".sidebar").classList.contains("locked")) {
  //     document.querySelector(".sidebar").classList.add("hoverable");
  //     document.querySelector("#lock-icon").classList.replace("bx-lock-alt", "bx-lock-open-alt");
  //   } else {
  //     document.querySelector(".sidebar").classList.remove("hoverable");
  //     document.querySelector("#lock-icon").classList.replace("bx-lock-open-alt", "bx-lock-alt");
  //   }
  // };
  
  // Function to hide the sidebar when the mouse leaves
  const hideSidebar = () => {
    if (document.querySelector(".sidebar").classList.contains("hoverable")) {
      document.querySelector(".sidebar").classList.add("closenav");
    }
  };
  
   // Function to show and hide the sidebar
  //  const toggleSidebar = () => {
  //   document.querySelector(".sidebar").classList.toggle("closenav");
  // };

  if (window.innerWidth < 800) {
    document.querySelector(".sidebar").classList.add("closenav");
    document.querySelector(".sidebar").classList.remove("locked");
    document.querySelector(".sidebar").classList.remove("hoverable");
  }
  


  return (
    <nav className="sidebar hoverable closenav">
      <div className="menu_container">
        <div className="menu_items">
          <ul className="menu_item">
            
            <li className="item imenu">
              <a href="#" className="link flex">
                <i className="bx bxs-truck"></i>
                <span>Courier Tracking</span>
              </a>
              <ul className="menu_item smn">
                <li className="item">
                  <a href="/couriers" className="link flex">
                  <i className="bx bx-category-alt"></i>
                  <span>Courier Consignments</span>
                  </a>
                </li>
                  {/* <li className="item">
                  <a href="/dashboard" className="link flex">
                  <i className="bx bxs-mobile"></i>
                  <span>Performance Dashboard</span>
                  </a>
                </li>     */}
              </ul>
            </li>

            <li className="item imenu">
              <a href="#" className="link flex">
                <i className="bx bx-trip"></i>
                <span>Track And Trace</span>
              </a>
              <ul className="menu_item smn">
                <li className="item">
                  <a href="/tracking" className="link flex">
                  <i className="bx bx-category-alt"></i>
                  <span>Consignments</span>
                  </a>
                </li>
                
                <li className="item">
                  <a href="/simscreen" className="link flex">
                  <i className="bx bxs-mobile"></i>
                  <span>Sim Tracking Dashboard</span>
                  </a>
                </li>
                
               
                {/* <li className="item">
                  <a href="/manage" className="link flex">
                  <i className="bx bxs-mobile"></i>
                  <span>Manage</span>
                  </a>
                </li> */}
                {/* <li className="item">
                  <a href="/sample" className="link flex">
                  <i className="bx bxs-mobile"></i>
                  <span>Sim Tracking Dashboard</span>
                  </a>
                </li> */}
                
              </ul>
            </li>
            
            {
            (localStorage.getItem("roles") == "ADMIN")?
            <li className="item">
              <a href="/customers" className="link flex">
              <i className="bx bxs-mobile"></i>
              <span>Customers</span>
              </a>
            </li>
            :""}
             {
            (localStorage.getItem("roles") == "ADMIN")?
            <li className="item">
              <a href="/customerfeedbacks" className="link flex">
              <i className="bx bxs-mobile"></i>
              <span>Customer Feedbacks</span>
              </a>
            </li>
          :""}
           {
            (localStorage.getItem("roles") == "ADMIN")?
          <li className="item">
            <a href="/plantmaster" className="link flex">
              <i class='bx bxs-factory' style={{ fontSize: '20px' }}></i>
              <span>Plant Master</span>
            </a>
          </li>
          :""}
          {
            (localStorage.getItem("roles") == "ADMIN")?

            <li className="item">
              <a href="/usermanagement" className="link flex">
                <i class='bx bxs-user-detail' style={{ fontSize: '20px' }}></i>
                <span>User Management</span>
              </a>
            </li>
            :""

          }


            
            <li className="item">
              <a href="/manualconsignmenttracking" className="link flex">
              <i className="bx bxs-location-plus"></i>
              <span>Manual Consignment Tracking</span>
              </a>
            </li>

            <li className="item">
              <a href="/consolidatedreportscreen" className="link flex">
              <i className="bx bxs-report"></i>
              <span>Consolidated Report Screen</span>
              </a>
            </li>

            <li className="item">
              <a href="/tms" className="link flex">
              {/* <i className="bx bxs-mobile"></i> */}
              <i className='bx bxs-coupon'></i>
              <span>Ticket Management System</span>
              </a>
            </li>

            

             {/* <li className="item imenu">
                <a href="/feedback" className="link flex">
                <i className="bx bx-comment"></i>
                <span>Nipro Feed Back Form</span>
                </a>
              </li> */}
            
          </ul>
        </div>

        
      </div>
    </nav>
  );
}


export default Sidebar;
