import React, {useState, useEffect } from 'react';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import redirectURL from '../redirectURL';
import FileUploadField from './FileUploadField';

// Component
const ReportIssueModal = ({ showModal, handleClose }) => {
  var [show, setShow] = useState(false);
  var [basicTitle, setBasicTitle] = useState('false');
  var [basicType, setBasicType] = useState('');
  const [loadShow, setLoadShow] = useState('show-n');

  const navigate = useNavigate();

  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [description, setDescription] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  var [uploadDoc, setuploadDoc] = useState([]);
  const [issueOptions, setIssueOptions] = useState(null);
  const [severityOptions, setIssueSeverityOptions] = useState(null);
  const [severityColor, setSeverityColor] = useState('#000');

useEffect(() => {
  const fetchIssueTypeOptions = async () => {
    const params = {}; // Define any params your request needs
    try {
      const response = await redirectURL.post('/getIssueTypeOptions', params);
      console.log(response.data, 'success');
      const options = response.data.records.map((plant) => ({
        value: plant.issue_type_name,
        label: `${plant.issue_type_name}`
      }));
      console.log(options, 'myoptions');
      setIssueOptions(options);
    } catch (error) {
      console.error('Failed to fetch plant options:', error);
    }
  };
  fetchIssueTypeOptions();

  const fetchIssueSeverityTypeOptions = async () => {
    const params = {}; // Define any params your request needs
    try {
      const response = await redirectURL.post('/getIssueSeverityTypeOptions', params);
      console.log(response.data, 'success');
      const options = response.data.records.map((plant) => ({
        value: plant.severity_type_code,
        label: `${plant.severity_type_name}`,
        color: `${plant.color}`
      }));
      console.log(options, 'myoptions');
      setIssueSeverityOptions(options);
    } catch (error) {
      console.error('Failed to fetch plant options:', error);
    }
  };
  fetchIssueSeverityTypeOptions();
  
}, []);


const [issueDetails, setIssueDetails] = useState({
  issueType: '',
  description: '',
  severity: '',
  email: localStorage.getItem('email'), // Assuming you can auto-capture this
  phoneNumber: '',
  createdDate: new Date(),
  status: "Open",
  is_processed: 0
});
console.log(issueDetails, 'mydate');
const referenceNum = issueDetails.createdDate.toISOString().replace(/\D/g, '');
// console.log(referenceNum, 'referenceNum');

const closeAlert = () => {
  setShow(false);
  handleClose()
  navigate('/tms');

};

const onClickClose = () => {
  navigate('/');
};

const handleIssueChange = (selectedOption) => {
  // Update both local state and issueDetails state
  setSelectedIssue(selectedOption);
  const updatedIssueType = selectedOption ? selectedOption.value : '';
  setIssueDetails({ ...issueDetails, issueType: updatedIssueType });
};

const handleDescriptionChange = (event) => {
  console.log(event.target.value);
  setDescription(event.target.value);
  const updatedIssueType = event ? event.target.value : '';
  setIssueDetails({ ...issueDetails, description: updatedIssueType });
};

const handleSeverityChange = (selectedOption) => {
  setSeverityColor(selectedOption.color);
  // Update both local state and issueDetails state
  setSelectedSeverity(selectedOption);
  const updatedSeverity = selectedOption ? selectedOption.value : '';
  setIssueDetails({ ...issueDetails, severity: updatedSeverity });
};

var handleFileChange = (event) => {
  console.log("2183", event)

  setuploadDoc(event.target.files[0])
}

const handleInputChange = (e) => {
  const { name, value } = e.target;
  var isValidStart = /^[6-9][0-9]*$/;   
  if (value !== "" && isValidStart.test(value) && value.length <= 10) {
    setMobileNumber(value);    
  } else if (value === "") {
    setMobileNumber("");
  }
  setIssueDetails({ ...issueDetails, [name]: value });
};

const handleSubmit = async (e) => {
  setLoadShow("show-m")
  e.preventDefault();
  // Here, you would typically send the issueDetails to your backend server
  console.log(issueDetails, uploadDoc, selectedIssue, "sssssssssssssssssssss");
  var formData2 = new FormData();
  formData2.append("podDoc", uploadDoc);
  formData2.append("issueType", issueDetails.issueType);
  formData2.append("description", issueDetails.description);
  formData2.append("severity", issueDetails.severity);
  formData2.append("email", issueDetails.email);
  formData2.append("phoneNumber", issueDetails.phoneNumber);
  formData2.append("referenceNum", referenceNum);
  formData2.append("createdDate", issueDetails.createdDate);
  formData2.append("status", issueDetails.status);


  // await redirectURL.post('/triggeremail', {}).then((response) => {

  // })
  console.log(formData2, "ssssssssssssssssssss2222222");
  await redirectURL.post('/savereportdata', formData2, {
    headers: {
      'content-type': "multipart/form-data"
    }
  })
    .then((response) => {
      console.log(response, 'response');
      if (response.data.status == 'Success' && response.status == 200) {
        setLoadShow("show-n")
        setShow(true);
        setBasicTitle(`Issue Reported with Id ${referenceNum}`);
        setBasicType('success');

        // window.location.href="/";
      } else {
        setLoadShow("show-n")
        setShow(true);
        setBasicTitle('Something went wrong, please try again');
        setBasicType('danger');
      }
    });
  setSelectedIssue(null);
  setSelectedSeverity(null);
  setDescription('');
  setMobileNumber('');
  setuploadDoc('');
};



   // Default color
  // const handleSeverityChange = (selectedOption) => {
  //    // Update color based on selection
  // };

  if (!showModal) return null;


  const customOption = (props) => {
    const { data } = props;
    const Icon = data.icon;
    return (
      <components.Option {...props}>
        <Icon style={{ marginRight: 8 }} size={16} />
        {data.label}
      </components.Option>
    );
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log('File selected:', file);
  //   // Handle file logic
  // };

  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      textAlign: 'left',
      minWidth: 240,
      // margin: 8
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left' // Align placeholder text to the left
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
    color: state.isSelected ? 'white' : 'black', // Changes text color to white when option is selected
    backgroundColor: state.isFocused ? 'lightgray' : state.isSelected ? 'blue' : 'white', // Adds background color for focused state
    display: 'flex', // Keeps label and icon in line
    alignItems: 'center', // Centers the content vertically
    paddingLeft: 20, // Padding left for text and icon inside the option
    cursor: 'pointer' // **New Change**: Changes the cursor to a pointer on hover
    }),
  };

  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      textAlign: 'left', // Align text inside the control to the left
      minWidth: 240,
      // margin: 8,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      backgroundColor: state.isFocused ? state.data.color : 'transparent',
      textAlign: 'left', // Align text in the dropdown options to the left
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'left' // Align selected value text to the left
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left' // Align placeholder text to the left
    })
  };
  
  return (
    <div className='modalBackdrop'>
      <SweetAlert show={show} type={basicType} title={basicTitle} onConfirm={closeAlert}></SweetAlert>
      <div className='modalContainer' onClick={e => e.stopPropagation()}>
        <h3 className='modalHeader'> 
          <span className='reportIssueIcon'><i class='bx bxs-error-circle f30'></i></span>
          Report Issue
        </h3>
        <form onSubmit={handleSubmit}>
          <div className='formRow'>
          <div className='reportissueform-field'>
            <label className='reportIssueLabel'>
              Issue Category
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </label>

            <Select
            name="issueType"
            value={selectedIssue}
            onChange={handleIssueChange}
            options={issueOptions}
            placeholder="Select an Issue"
            isClearable
            isSearchable
            required={true}
            styles={customStyles2}
            // styles={{ container: (base) => ({ ...base, width: '100%', borderRadius: "10px" }) }}
            />
          </div>
          <div className='reportissueform-field'>
              <label className='reportIssueLabel'>
                Severity
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <Select
                          name="severitytype"
                          value={selectedSeverity}
                          onChange={handleSeverityChange}
                          options={severityOptions}
                          placeholder="Select Severity"  // Adjusted to match the function of the select
                          isClearable
                          isSearchable
                          required={true}
                          styles={customStyles}
                          // styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                        />
            </div>
          </div>
          <div className='formRow'>

          <div className='reportissueform-field'>
              <label className='reportIssueLabel'>Phone Number (Optional)</label>
              <input type="text" name="phoneNumber" placeholder='Enter Phone No' value={mobileNumber} onChange={handleInputChange} style={{ width: '100%', height: '38px', marginBottom: "0px" }} />
              {/* <Input placeholder="Add Phone Number" /> */}
            </div>
            
            <div className='reportissueform-field'>
              <FileUploadField  handleFileChange={handleFileChange} />
            </div>
          </div>
          <div className='formRow'>
            <div className='reportissueform-field'>
              <label className='reportIssueLabel'>Description 
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <textarea
                          name="description"
                          value={description}
                          onChange={handleDescriptionChange}
                          style={{marginBottom: "0px"}}
                          // style={{ marginBottom: "23px" }}
                          required
                          rows="6"
                          cols="30"
                        />
            </div>
          </div>
          <div className='buttonContainer'>
            <button className='reportIssueButton' type="button" onClick={handleClose}>Close</button>
            <button className='reportIssueButton' type="submit">Submit</button>
          </div>
        </form>
      </div>
      <div className={"dataLoad "+loadShow}></div>
            <div className={"dataLoadpageimg " + (loadShow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
    </div>
  );
};

export default ReportIssueModal;